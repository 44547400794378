import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    PostPaymentBill,
    CreateBillRequest,
    GetBillCountRequest,
    GetBillCountResponse,
    GetBillListRequest, GetBillPdfPathResponse, SendBillByEmailRequest
} from "@/store/modules/postPaymentBill/models";
import {Bill} from "@/store/modules/bills/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetBillListRequest): Promise<PostPaymentBill[]> {
            const response  = await api.get<PostPaymentBill[]>('api/lk/v1/post_payment_bills', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetBillListRequest): Promise<PostPaymentBill[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getCount(ctx, request: GetBillCountRequest): Promise<number> {
            const response  = await api.get<GetBillCountResponse>('api/lk/v1/post_payment_bills/count', { params: request });
            return response.data.count;
        },
        async createBill(ctx, request: CreateBillRequest): Promise<PostPaymentBill> {
            const response  = await api.post<PostPaymentBill>('api/lk/v1/post_payment_bills', request);
            return response.data;
        },
        async sendBillByEmail(ctx, request: SendBillByEmailRequest) {
            await api.post(`api/lk/v1/post_payment_bills/${request.billId}/send_email`, request);
        },
        async getBillPdfPath(ctx, id: number): Promise<string> {
            const response = await api.get<GetBillPdfPathResponse>(`api/lk/v1/post_payment_bills/${id}/pdf/path`);
            return response.data.path;
        },
        async createBillForBalance(ctx, id: number): Promise<Bill> {
            const response = await api.post<Bill>(`api/lk/v1/post_payment_bills/${id}/create_bill_for_balance`, {});
            return response.data;
        }
    }
};