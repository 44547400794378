import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  //   AUTH
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/LoginView.vue')
  },
  {
    path: '/',
    name: 'main',
    component: () => import('../views/main/MainView.vue')
  },
  {
    path: '/oil-cards/:oilCardId/limit/:limitId',
    name: 'oil-card-limit',
    component: () => import('../views/oilCards/OilCardLimitView.vue'),
  },
  {
    path: '/oil-cards/:oilCardId/limit',
    name: 'new-oil-card-limit',
    component: () => import('../views/oilCards/OilCardLimitView.vue'),
  },
  {
    path: '/oil-cards/:oilCardId/restriction/:restrictionId',
    name: 'oil-card-restriction',
    component: () => import('../views/oilCards/OilCardRestrictionView.vue'),
  },
  {
    path: '/oil-cards/:oilCardId/restriction',
    name: 'new-oil-card-restriction',
    component: () => import('../views/oilCards/OilCardRestrictionView.vue'),
  },
  {
    path: '/oil-cards/:oilCardId',
    name: 'oil-card',
    component: () => import('../views/oilCards/OilCardView.vue'),
  },
  {
    path: '/oil-cards',
    name: 'oil-cards',
    component: () => import('../views/oilCards/OilCardsView.vue'),
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('../views/transactions/TransactionsView.vue')
  },
  {
    path: '/finance/bills',
    name: 'bills',
    component: () => import('../views/finance/bills/BillsView.vue')
  },
  {
    path: '/finance/post-payment-bills',
    name: 'post-payment-bills',
    component: () => import('../views/finance/postPaymentBills/PostPaymentBills.vue')
  },
  {
    path: '/finance/payments',
    name: 'payments',
    component: () => import('../views/finance/payments/PaymentsView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( '../views/news/NewsView.vue')
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import( '../views/archive/ArchiveView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
