import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "notification-title" }
const _hoisted_2 = {
  key: 0,
  class: "notification-content"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "notification-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_metainfo, null, {
        title: _withCtx(({ content }) => [
          _createTextVNode(_toDisplayString(content ? `${content} | ${_ctx.siteName}` : _ctx.siteName), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_view),
      _createVNode(_component_notifications, {
        group: "report",
        duration: 15000
      }, {
        body: _withCtx(({item}) => [
          _createElementVNode("div", {
            class: _normalizeClass(['vue-notification-template vue-notification', item.type])
          }, [
            _createElementVNode("div", _hoisted_1, _toDisplayString(item.title), 1),
            (item.type === 'success')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _cache[0] || (_cache[0] = _createTextVNode(" Отчет успешно сформирован")),
                  _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("span", {
                    style: {"cursor":"pointer","font-weight":"800"},
                    onClick: ($event: any) => (_ctx.downloadReport(item))
                  }, "Сохранить", 8, _hoisted_3)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.text), 1))
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_notifications)
    ]),
    _: 1
  }))
}