import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {DownloadReportRequest} from "@/store/modules/common/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: <ActionTree<any, any>>{
        async downloadReport(ctx, request: DownloadReportRequest): Promise<void> {
            const response  = await api.get<any>('/lk/api/v1/download_report', {
                params: request,
                responseType: 'blob',
            });
            const reader = new FileReader()
            console.log(response.data)
            reader.readAsDataURL(response.data)
            reader.onload = (e) => {
                const a = document.createElement('a')
                a.download = request.fileName
                a.href = String(e.target?.result)
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
            }
        }
    }
};