import { createStore } from 'vuex'
import auth from "@/store/modules/auth";
import companyContract from "@/store/modules/companyContract";
import account from "@/store/modules/account";
import oilCard from "@/store/modules/oilCard";
import news from "@/store/modules/news";
import payments from "@/store/modules/payments";
import bills from "@/store/modules/bills";
import postPaymentBill from "@/store/modules/postPaymentBill";
import transactions from '@/store/modules/transactions';
import oilGroup from "@/store/modules/oilGroup";
import limit from "@/store/modules/limit";
import restriction from "@/store/modules/restriction";
import common from "@/store/modules/common";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    reset(ctx) {
      ctx.dispatch('account/reset');
    }
  },
  modules: {
    common: common,
    auth: auth,
    bills: bills,
    postPaymentBill: postPaymentBill,
    companyContract: companyContract,
    account: account,
    oilCard: oilCard,
    news: news,
    payments: payments,
    transactions: transactions,
    oilGroup: oilGroup,
    limit: limit,
    restriction: restriction,
  }
})
