import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    CreateLimitRequest,
    DeleteAllRequest,
    GetLimitProductCategoriesRequest, GetLimitProductsRequest,
    GetLimitsRequest,
    Limit,
    LimitPeriod, LimitProduct, LimitProductCategory,
    LimitUnit, SaveLimitRequest,
    SaveLimitsRequest, SaveLimitsRequestItem
} from "@/store/modules/limit/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {
        map: {} as Record<string, Limit[]>,
        units: [] as LimitUnit[],
        periods: [] as LimitPeriod[],
        products: [] as LimitProduct[],
        categories: [] as LimitProductCategory[],
    },
    getters: {
        limitsByOilCard(state) {
            return function (oilCardId: string): Limit[] {
                return state.map[oilCardId] ? state.map[oilCardId] : [];
            }
        },
        limitUnits(state): LimitUnit[] {
            return state.units;
        },
        limitUnitsByIds(state) {
            return function (ids: string[]): LimitUnit[] {
                return state.units.filter((unit: LimitUnit): boolean => ids.includes(unit.id));
            }
        },
        limitPeriods(state): LimitPeriod[] {
            return state.periods;
        },
        limitProducts(state): LimitProduct[] {
            return state.products;
        },
        limitProductsByCategory(state) {
            return function (categoryId: string): LimitProduct[] {
                return state.products.filter((product: LimitProduct): boolean => product.categoryId === categoryId);
            }
        },
        limitProductCategories(state): LimitProductCategory[] {
            return state.categories;
        },
    },
    mutations: {
        setMap(state, {oilCardId, data}) {
            state.map[oilCardId] = data;
        },
        updateMap(state, {oilCardId, data}) {
            const listToUpdate: Limit[] = data;
            const currentList: Limit[] = state.map[oilCardId] ? state.map[oilCardId] : [];
            listToUpdate.forEach(updatedLimit => {
                const index = currentList.findIndex(limit => limit.id === updatedLimit.id);
                if (-1 !== index) {
                    currentList.splice(index, 1, updatedLimit);
                }
            });
            currentList.forEach((limit, index) => {
                const updatedIndex = listToUpdate.findIndex(updatedLimit => updatedLimit.id === limit.id);
                if (-1 === updatedIndex && limit.id && limit.isDeleted && !limit.isSetInOilCompany) {
                    currentList.splice(index, 1);
                }
            });
        },
        setUnits(state, data) {
            state.units = data;
        },
        setPeriods(state, data) {
            state.periods = data;
        },
        setProducts(state, data) {
            state.products = data;
        },
        setProductCategories(state, data) {
            state.categories = data;
        },
    },
    actions: <ActionTree<any, any>>{
        async fetchOne(ctx, id: string): Promise<Limit> {
            const response  = await api.get<Limit>(`api/lk/v1/limits/${id}`);
            return response.data;
        },
        async getOneWithoutSave(ctx, id: string): Promise<Limit> {
            return await ctx.dispatch('fetchOne', id);
        },
        async fetchList(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            const response  = await api.get<Limit[]>('api/lk/v1/limits', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getListWithSave(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            const data = await ctx.dispatch('fetchList', request);
            const oilCardId = request.oilCardId;
            ctx.commit('setMap', {oilCardId, data});
            return data;
        },
        async updateList(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            const data = await ctx.dispatch('fetchList', request);
            const oilCardId = request.oilCardId;
            ctx.commit('updateMap', {oilCardId, data});
            return data;
        },
        async getUnits(ctx): Promise<LimitUnit[]> {
            const response  = await api.get<LimitUnit[]>('api/lk/v1/limit_units');
            ctx.commit('setUnits', response.data);

            return response.data;
        },
        async getPeriods(ctx): Promise<LimitPeriod[]> {
            const response  = await api.get<LimitPeriod[]>('api/lk/v1/limit_periods');
            ctx.commit('setPeriods', response.data);

            return response.data;
        },
        async getProducts(ctx, request: GetLimitProductsRequest): Promise<LimitProduct[]> {
            const response  = await api.get<LimitProduct[]>('api/lk/v1/limit_products', { params: request });
            ctx.commit('setProducts', response.data);

            return response.data;
        },
        async getProductCategories(ctx, request: GetLimitProductCategoriesRequest): Promise<LimitProductCategory[]> {
            const response  = await api.get<LimitProductCategory[]>('api/lk/v1/limit_product_categories', { params: request });
            ctx.commit('setProductCategories', response.data);

            return response.data;
        },
        clearProductCategories({commit}) {
            commit('setProductCategories', []);
        },
        async saveLimits(ctx, request: SaveLimitsRequest) {
            await api.post('api/lk/v1/limits', request);
        },
        async saveOne(ctx, request: SaveLimitRequest): Promise<void> {
            await api.post(`api/lk/v1/limits/${request.id}`, request);
        },
        async createOne(ctx, request: CreateLimitRequest): Promise<void> {
            await api.post(`api/lk/v1/limits/create`, request);
        },
        async deleteOne(ctx, id: string) {
            await api.delete(`api/lk/v1/limits/${id}`);
        },
        async deleteAll(ctx, request: DeleteAllRequest) {
            await api.delete(`api/lk/v1/limits`, { data: request });
        }
    }
};