import { createApp } from 'vue'
import Vuelidate from '@vuelidate/core'
import Notifications from '@kyvg/vue3-notification'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { createMetaManager } from 'vue-meta'
import { createYmaps } from 'vue-yandex-maps';

import { loadFonts } from './plugins/webfontloader'
import '@/design/main.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@vuepic/vue-datepicker/dist/main.css'

import {filters} from "@/helpers/filters";

loadFonts()

const app = createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(Vuelidate as any)
    .use(Notifications)
    .use(createMetaManager())
    .use(createYmaps({
        apikey: process.env.VUE_APP_YA_MAP_API_KEY,
    }));

app.config.globalProperties.$filters = filters;

app.mount('#app')
